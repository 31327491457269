import { BrowserRouter, Route, Routes } from "react-router-dom"

import {
  FAQ,
  Farms,
  Home,
  Pool,
  PoolInfo,
  Privacy,
  Referral,
  Swap,
  SwapInfo,
  Terms,
  Token,
  Cookies,
} from "pages"
import { routes } from "utils"

export const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.index} element={<Home />} />
        <Route path={routes.farms} element={<Farms />} />
        <Route path={routes.terms} element={<Terms />} />
        <Route path={routes.privacy} element={<Privacy />} />
        <Route path={routes.referral} element={<Referral />} />
        <Route path={routes.pool} element={<Pool />} />
        <Route path={routes.swapInfo} element={<SwapInfo />} />
        <Route path={routes.swap} element={<Swap />} />
        <Route path={routes.faq} element={<FAQ />} />
        <Route path={routes.cookies} element={<Cookies />} />
        <Route path={routes.token}>
          <Route path=":id" element={<Token />} />
        </Route>
        <Route path={routes.poolInfo}>
          <Route path=":id" element={<PoolInfo />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
