export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "https://x.com/ceo_finance",
  mail: "",
  discord: "",
  scan: "",
  telegram: "https://t.me/ceo_finance",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  whitePaper: "",
  contract: "",
};
