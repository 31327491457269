export const routes = {
  index: "/",

  farms: "/farms",
  team: "/team",
  faq: "/faq",
  terms: "/terms",
  privacy: "/privacy",
  cookies: "/cookies",
  referral: "/referral",
  pool: "/pool",
  swap: "/swap",
  swapInfo: "/analytics",
  token: "/token",
  poolInfo: "/pool-info",
  docs: "/docs",
  stake: "/stake",
  earn: "/earn",
  news: "/news",
  articles: "/articles",
};
