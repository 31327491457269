import React from "react"

import "./Loading.scss"
import imgMainBgSrc from "../../assets/images/main-bg.webp";
import imgLogo from "../../assets/icons/logo-mobile.svg";
import imgLoading from "../../assets/icons/loading.svg";

export const Loading = (): JSX.Element => (
  <div className="loading" >
    <div className="loading-backdrop" style={{ backgroundImage: `url(${imgMainBgSrc})` }} />
    <div className='loading-content'>
      <img src={imgLoading} alt='logo' className='loading-content-loading'/>
      <img src={imgLogo} alt='logo' className='loading-content-logo'/>
    </div>
  </div>
)
