import React from "react"

import { ReactComponent as Icon1 } from "assets/icons/percents.svg"
import { ReactComponent as Icon2 } from "assets/icons/coin.svg"
import { ReactComponent as Icon3 } from "assets/icons/discover.svg"
import { ReactComponent as Icon4 } from "assets/icons/chart.svg"
import "./Features.scss"
import { routes } from "../../utils"
import { Link, useSearchParams } from "react-router-dom"

export const Features = (): JSX.Element => {
  const [search] = useSearchParams()

  const blocks = [
    {
      title: "Multi-Chain Liquidity \nProvision",
      desc: "Contribute to the growth of the crypto ecosystem by providing liquidity across multiple blockchains and earning attractive interest on your supplied assets.",
      icon: <Icon1 />,
      btnText: "View Supported Chains & Assets",
      route: `${routes.farms}?${search.toString()}`,
    },
    {
      title: "Flexible Staking \nOptions",
      desc: "Tailor your staking experience with a wide variety of supported cryptocurrencies, allowing you to maximize your earnings potential based on your unique preferences and risk tolerance.",
      icon: <Icon2 />,
      btnText: "Discover Staking Opportunities",
      route: `${routes.pool}?${search.toString()}`,
    },
    {
      title: "Discover Staking \nOpportunities",
      desc: "Experience peace of mind with our safe and reliable decentralized smart contracts, designed to ensure the security and autonomy of your staking transactions.",
      icon: <Icon3 />,
      btnText: "Browse Contract List",
      route: `${routes.pool}?${search.toString()}`,
    },
    {
      title: "Rewarding Referral \nProgram",
      desc: "Share the benefits of Ceo Finance with friends and earn valuable rewards! Invite your network to join our platform and receive generous referral bonuses as they stake and grow their crypto assets.",
      icon: <Icon4 />,
      btnText: "View Referral Program Details",
      route: `${routes.referral}?${search.toString()}`,
    },
  ]
  return (
    <div className="features">
      <h3 className="features-title">Empowering Features</h3>
      <p className="features-desc">Enhance Your Multi-Chain Staking Experience with Ceo Finance</p>
      <div className="features-content">
        {blocks.map((block, index) => (
          <div key={index} className="features-content-item">
            <div className="features-content-item-icon">{block.icon}</div>
            <div className='features-content-item-right'>
              <div className="features-content-item-title">{block.title}</div>
              <div className="features-content-item-desc">{block.desc}</div>
              <Link to={block.route} className="features-content-item-link">
                {block.btnText}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
