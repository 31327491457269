import React, { useEffect, useState } from "react"
import {Header, Footer, SwapTable, SwapChartRight, SwapMover, SwapTablePairs, Loading} from "components"

import "./SwapInfo.scss"

import { ReactComponent as Arrow } from "assets/icons/arrow-right.svg"
import cn from "classnames"
import { apiCoin } from "../../service/api/apiCoinGecko"
import { useNavigate, useSearchParams } from "react-router-dom"
import { api } from "../../service/api/api"
import { ethers } from "ethers"
import {
  approveAddress, contractAddressApe,
  contractAddressApeApprove, contractAddressArbArbitrum, contractAddressArbArbitrumApprove,
  contractAddressBabyDogeApprove, contractAddressBnb, contractAddressBusd,
  contractAddressBusdApprove, contractAddressC98Approve,
  contractAddressCakeApprove, contractAddressEth,
  contractAddressGmtApprove, contractAddressLink,
  contractAddressLinkApprove, contractAddressLinkArbitrum, contractAddressLinkArbitrumApprove,
  contractAddressTwtApprove, contractAddressUni,
  contractAddressUniApprove, contractAddressUsdc,
  contractAddressUsdcApprove, contractAddressUsdt, contractAddressWbtc,
  contractAddressWbtcApprove, contractAddressWbtcArbitrum, contractAddressWbtcArbitrumApprove,
} from "../../abi"
import abiApprove from "../../abi/abiApprove.json"
import abiUsdcApprove from "../../abi/abiUsdcApprove.json"
import abiUniApprove from "../../abi/abiUniApprove.json"
import abiLinkApprove from "../../abi/abiLinkApprove.json"
import abiWbtcApprove from "../../abi/abiWbtcApprove.json"
import abiApeApprove from "../../abi/abiApeApprove.json"
import imgMainBgSrc from "assets/images/main-bg.webp"
import abiArbStandartApprove from "../../abi/abiArbitrumStandartApprove.json"
import abiArbApprove from "../../abi/abiArbApprove.json"
import { poolDataSeparated } from "../../components/Table/dataSeparated"
import { routes } from "../../utils"
import {apiScan} from "../../service/api/apiScan";
import abiBusdApprove from "../../abi/abiBusdApprove.json";
import {poolDataSeparatedBsc} from "../../components/Table/dataSeparatedBsc";
import {useAccount} from "wagmi";
import {getChainId} from "@wagmi/core";
import {config} from "../../index";

export const SwapInfo = (): JSX.Element => {
  const [search] = useSearchParams()
  const navigate = useNavigate()
  const { address } = useAccount();
  const chainId = getChainId(config)
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState("overview")
  const [translateX, setTranslateX] = useState(0)
  const [topTokens, setTopTokens] = useState<any[]>([])
  const [ourTvlData, setOurTvlData] = useState<any[]>([])
  const busd = 1000000000000000000
  const [totalBalanceEth1, setTotalBalanceEth1] = useState(0)
  const [totalBalanceEth2, setTotalBalanceEth2] = useState(0)
  const [totalBalanceEth3, setTotalBalanceEth3] = useState(0)
  const [totalBalanceEth4, setTotalBalanceEth4] = useState(0)
  const [totalBalanceBnb1, setTotalBalanceBnb1] = useState(0)
  const [totalBalanceBnb2, setTotalBalanceBnb2] = useState(0)
  const [totalBalanceBnb3, setTotalBalanceBnb3] = useState(0)
  const [totalBalanceBnb4, setTotalBalanceBnb4] = useState(0)
  const [totalBalanceBnb5, setTotalBalanceBnb5] = useState(0)
  const [totalBalanceUsdt1, setTotalBalanceUsdt1] = useState(0)
  const [totalBalanceUsdt2, setTotalBalanceUsdt2] = useState(0)
  const [totalBalanceUsdt3, setTotalBalanceUsdt3] = useState(0)
  const [totalBalanceUsdt4, setTotalBalanceUsdt4] = useState(0)
  const [totalBalanceBusd1, setTotalBalanceBusd1] = useState(0)
  const [totalBalanceBusd2, setTotalBalanceBusd2] = useState(0)
  const [totalBalanceBusd3, setTotalBalanceBusd3] = useState(0)
  const [totalBalanceBusd4, setTotalBalanceBusd4] = useState(0)
  const [totalBalanceBusd5, setTotalBalanceBusd5] = useState(0)
  const [totalBalanceUsdc1, setTotalBalanceUsdc1] = useState(0)
  const [totalBalanceUsdc2, setTotalBalanceUsdc2] = useState(0)
  const [totalBalanceUsdc3, setTotalBalanceUsdc3] = useState(0)
  const [totalBalanceUsdc4, setTotalBalanceUsdc4] = useState(0)
  const [totalBalanceCake1, setTotalBalanceCake1] = useState(0)
  const [totalBalanceCake2, setTotalBalanceCake2] = useState(0)
  const [totalBalanceCake3, setTotalBalanceCake3] = useState(0)
  const [totalBalanceCake4, setTotalBalanceCake4] = useState(0)
  const [totalBalanceCake5, setTotalBalanceCake5] = useState(0)
  const [totalBalanceUni1, setTotalBalanceUni1] = useState(0)
  const [totalBalanceUni2, setTotalBalanceUni2] = useState(0)
  const [totalBalanceUni3, setTotalBalanceUni3] = useState(0)
  const [totalBalanceUni4, setTotalBalanceUni4] = useState(0)
  const [totalBalanceBaby1, setTotalBalanceBaby1] = useState(0)
  const [totalBalanceBaby2, setTotalBalanceBaby2] = useState(0)
  const [totalBalanceBaby3, setTotalBalanceBaby3] = useState(0)
  const [totalBalanceBaby4, setTotalBalanceBaby4] = useState(0)
  const [totalBalanceBaby5, setTotalBalanceBaby5] = useState(0)
  const [totalBalanceLink1, setTotalBalanceLink1] = useState(0)
  const [totalBalanceLink2, setTotalBalanceLink2] = useState(0)
  const [totalBalanceLink3, setTotalBalanceLink3] = useState(0)
  const [totalBalanceLink4, setTotalBalanceLink4] = useState(0)
  const [totalBalanceLinkArb1, setTotalBalanceLinkArb1] = useState(0)
  const [totalBalanceLinkArb2, setTotalBalanceLinkArb2] = useState(0)
  const [totalBalanceLinkArb3, setTotalBalanceLinkArb3] = useState(0)
  const [totalBalanceLinkArb4, setTotalBalanceLinkArb4] = useState(0)
  const [totalBalanceTwt1, setTotalBalanceTwt1] = useState(0)
  const [totalBalanceTwt2, setTotalBalanceTwt2] = useState(0)
  const [totalBalanceTwt3, setTotalBalanceTwt3] = useState(0)
  const [totalBalanceTwt4, setTotalBalanceTwt4] = useState(0)
  const [totalBalanceTwt5, setTotalBalanceTwt5] = useState(0)
  const [totalBalanceWbtc1, setTotalBalanceWbtc1] = useState(0)
  const [totalBalanceWbtc2, setTotalBalanceWbtc2] = useState(0)
  const [totalBalanceWbtc3, setTotalBalanceWbtc3] = useState(0)
  const [totalBalanceWbtc4, setTotalBalanceWbtc4] = useState(0)
  const [totalBalanceWbtcArb1, setTotalBalanceWbtcArb1] = useState(0)
  const [totalBalanceWbtcArb2, setTotalBalanceWbtcArb2] = useState(0)
  const [totalBalanceWbtcArb3, setTotalBalanceWbtcArb3] = useState(0)
  const [totalBalanceWbtcArb4, setTotalBalanceWbtcArb4] = useState(0)
  const [totalBalanceArb1, setTotalBalanceArb1] = useState(0)
  const [totalBalanceArb2, setTotalBalanceArb2] = useState(0)
  const [totalBalanceArb3, setTotalBalanceArb3] = useState(0)
  const [totalBalanceArb4, setTotalBalanceArb4] = useState(0)
  const [totalBalanceGmt1, setTotalBalanceGmt1] = useState(0)
  const [totalBalanceGmt2, setTotalBalanceGmt2] = useState(0)
  const [totalBalanceGmt3, setTotalBalanceGmt3] = useState(0)
  const [totalBalanceGmt4, setTotalBalanceGmt4] = useState(0)
  const [totalBalanceGmt5, setTotalBalanceGmt5] = useState(0)
  const [totalBalanceApe1, setTotalBalanceApe1] = useState(0)
  const [totalBalanceApe2, setTotalBalanceApe2] = useState(0)
  const [totalBalanceApe3, setTotalBalanceApe3] = useState(0)
  const [totalBalanceApe4, setTotalBalanceApe4] = useState(0)
  const [totalBalanceApe5, setTotalBalanceApe5] = useState(0)
  const [totalBalanceCoin1, setTotalBalanceCoin1] = useState(0)
  const [totalBalanceCoin2, setTotalBalanceCoin2] = useState(0)
  const [totalBalanceCoin3, setTotalBalanceCoin3] = useState(0)
  const [totalBalanceCoin4, setTotalBalanceCoin4] = useState(0)
  const [totalBalanceCoin5, setTotalBalanceCoin5] = useState(0)
  const [connectModal, setConnectModal] = useState(false)
  const body = document.body

  useEffect(() => {
    if (!address) {
      setConnectModal(true)
    } else {
      setConnectModal(false)
      if (body) {
        body.style.overflow = "scroll"
        body.style.overflow = "scroll"
      }
    }
  }, [address])

  const onNextClick = () => {
    if (translateX === -1000) {
      setTranslateX(0)
    } else {
      setTranslateX(translateX - 500)
    }
  }

  const onPrevClick = () => {
    if (translateX === 0) {
      setTranslateX(-1000)
    } else {
      setTranslateX(translateX + 500)
    }
  }

  useEffect(() => {
    const id = setInterval(onNextClick, 2500)
    return () => clearInterval(id)
  }, [onNextClick, translateX])

  useEffect(() => {
    apiCoin
      .getStat()
      .then(rOther => {
        setTopTokens(rOther)
      })

  }, [])

  useEffect(() => {
    if (!!search.get("tab")) {
      setTab(search.get("tab") as any)
    }
  }, [search])

  const getAllInfoTvl = async () => {
    await apiCoin.getStat().then(async rOther => {
      const Result1Eth = await api.getBalance(contractAddressEth)
      setTotalBalanceEth1(
        (isNaN(Number(Result1Eth.result)) ? 0 : (poolDataSeparated[0].total + (Number(Result1Eth.result) / busd))) * rOther?.find(iOther => iOther.id === "ethereum")?.current_price,
      )
      setTotalBalanceEth2(
        (isNaN(Number(Result1Eth.result)) ? 0 : (poolDataSeparated[1].total + (Number(Result1Eth.result) / busd))) * rOther?.find(iOther => iOther.id === "ethereum")?.current_price,
      )
      setTotalBalanceEth3(
        (isNaN(Number(Result1Eth.result)) ? 0 : (poolDataSeparated[2].total + (Number(Result1Eth.result) / busd))) * rOther?.find(iOther => iOther.id === "ethereum")?.current_price,
      )
      setTotalBalanceEth4(
        (isNaN(Number(Result1Eth.result)) ? 0 : (poolDataSeparated[3].total + (Number(Result1Eth.result) / busd))) * rOther?.find(iOther => iOther.id === "ethereum")?.current_price,
      )
      {
        // @ts-ignore
                const { ethereum } = window
// @ts-ignore
        const provider = new ethers.providers.Web3Provider(ethereum)

        const tokenContractUsdt = new ethers.Contract(approveAddress, abiApprove, provider)
        const Result1Usdt = await tokenContractUsdt.balanceOf(contractAddressUsdt)
        setTotalBalanceUsdt1(
          (Number(Result1Usdt) / 1000000 + poolDataSeparated[4].total) * rOther?.find(iOther => iOther.id === "tether")?.current_price,
        )
        setTotalBalanceUsdt2(
          (Number(Result1Usdt) / 1000000 + poolDataSeparated[5].total) * rOther?.find(iOther => iOther.id === "tether")?.current_price,
        )
        setTotalBalanceUsdt3(
          (Number(Result1Usdt) / 1000000 + poolDataSeparated[6].total) * rOther?.find(iOther => iOther.id === "tether")?.current_price,
        )
        setTotalBalanceUsdt4(
          (Number(Result1Usdt) / 1000000 + poolDataSeparated[7].total) * rOther?.find(iOther => iOther.id === "tether")?.current_price,
        )

        const tokenContractUsdc = new ethers.Contract(contractAddressUsdcApprove, abiUsdcApprove, provider)
        const Result1Usdc = await tokenContractUsdc.balanceOf(contractAddressUsdc)
        setTotalBalanceUsdc1(
          (Number(Result1Usdc) / 1000000 + poolDataSeparated[8].total) * rOther?.find(iOther => iOther.id === "usd-coin")?.current_price,
        )
        setTotalBalanceUsdc2(
          (Number(Result1Usdc) / 1000000+ poolDataSeparated[9].total) * rOther?.find(iOther => iOther.id === "usd-coin")?.current_price,
        )
        setTotalBalanceUsdc3(
          (Number(Result1Usdc) / 1000000+ poolDataSeparated[10].total) * rOther?.find(iOther => iOther.id === "usd-coin")?.current_price,
        )
        setTotalBalanceUsdc4(
          (Number(Result1Usdc) / 1000000+ poolDataSeparated[11].total) * rOther?.find(iOther => iOther.id === "usd-coin")?.current_price,
        )

        const tokenContract = new ethers.Contract(contractAddressWbtcApprove, abiWbtcApprove, provider)
        const Result1 = await tokenContract.balanceOf(contractAddressWbtc)
        setTotalBalanceWbtc1(
          (Number(Result1) / 100000000 + poolDataSeparated[12].total) * rOther?.find(iOther => iOther.id === "wrapped-bitcoin")?.current_price,
        )
        setTotalBalanceWbtc2(
          (Number(Result1) / 100000000+ poolDataSeparated[13].total) * rOther?.find(iOther => iOther.id === "wrapped-bitcoin")?.current_price,
        )
        setTotalBalanceWbtc3(
          (Number(Result1) / 100000000+ poolDataSeparated[14].total) * rOther?.find(iOther => iOther.id === "wrapped-bitcoin")?.current_price,
        )
        setTotalBalanceWbtc4(
          (Number(Result1) / 100000000+ poolDataSeparated[15].total) * rOther?.find(iOther => iOther.id === "wrapped-bitcoin")?.current_price,
        )

        const tokenContractLink = new ethers.Contract(contractAddressLinkApprove, abiLinkApprove, provider)
        const Result1Link = await tokenContractLink.balanceOf(contractAddressLink)
        setTotalBalanceLink1(
          (Number(Result1Link) / busd + poolDataSeparated[16].total) * rOther?.find(iOther => iOther.id === "chainlink")?.current_price,
        )
        setTotalBalanceLink2(
          (Number(Result1Link) / busd+ poolDataSeparated[17].total) * rOther?.find(iOther => iOther.id === "chainlink")?.current_price,
        )
        setTotalBalanceLink3(
          (Number(Result1Link) / busd+ poolDataSeparated[18].total) * rOther?.find(iOther => iOther.id === "chainlink")?.current_price,
        )
        setTotalBalanceLink4(
          (Number(Result1Link) / busd+ poolDataSeparated[19].total) * rOther?.find(iOther => iOther.id === "chainlink")?.current_price,
        )

        const tokenContractUni = new ethers.Contract(contractAddressUniApprove, abiUniApprove, provider)
        const Result1Uni = await tokenContractUni.balanceOf(contractAddressUni)
        setTotalBalanceUni1(
          (Number(Result1Uni) / busd + poolDataSeparated[20].total) * rOther?.find(iOther => iOther.id === "uniswap")?.current_price,
        )
        setTotalBalanceUni2(
          (Number(Result1Uni) / busd + poolDataSeparated[21].total) * rOther?.find(iOther => iOther.id === "uniswap")?.current_price,
        )
        setTotalBalanceUni3(
          (Number(Result1Uni) / busd + poolDataSeparated[22].total) * rOther?.find(iOther => iOther.id === "uniswap")?.current_price,
        )
        setTotalBalanceUni4(
          (Number(Result1Uni) / busd + poolDataSeparated[23].total) * rOther?.find(iOther => iOther.id === "uniswap")?.current_price,
        )

        const tokenContractApe = new ethers.Contract(contractAddressApeApprove, abiApeApprove, provider)
        const Result1Ape = await tokenContractApe.balanceOf(contractAddressApe)

        setTotalBalanceApe1(
          (Number(Result1Ape) / busd + poolDataSeparated[24].total) * rOther?.find(iOther => iOther.id === "apecoin")?.current_price,
        )
        setTotalBalanceApe2(
          (Number(Result1Ape) / busd + poolDataSeparated[25].total) * rOther?.find(iOther => iOther.id === "apecoin")?.current_price,
        )
        setTotalBalanceApe3(
          (Number(Result1Ape) / busd + poolDataSeparated[26].total) * rOther?.find(iOther => iOther.id === "apecoin")?.current_price,
        )
        setTotalBalanceApe4(
          (Number(Result1Ape) / busd+ poolDataSeparated[27].total) * rOther?.find(iOther => iOther.id === "apecoin")?.current_price,
        )
        setTotalBalanceApe5(
          (Number(Result1Ape) / busd+ poolDataSeparated[28].total) * rOther?.find(iOther => iOther.id === "apecoin")?.current_price,
        )
      }
    }).finally(() => setLoading(false))
  }
  const getAllInfoBnb = async () => {
    await apiCoin.getStat().then(async rOther => {
      // @ts-ignore
      const { ethereum } = window
      // @ts-ignore
      const provider = new ethers.providers.Web3Provider(ethereum)

      const Result1Bnb = await apiScan.getBalance(contractAddressBnb)
      setTotalBalanceBnb1(((Number(Result1Bnb.result) / busd) + poolDataSeparatedBsc[0].total) * rOther?.find(iOther => iOther.id === "binancecoin")?.current_price)
      setTotalBalanceBnb2(((Number(Result1Bnb.result) / busd) + poolDataSeparatedBsc[1].total) * rOther?.find(iOther => iOther.id === "binancecoin")?.current_price)
      setTotalBalanceBnb3(((Number(Result1Bnb.result) / busd) + poolDataSeparatedBsc[2].total) * rOther?.find(iOther => iOther.id === "binancecoin")?.current_price)
      setTotalBalanceBnb4(((Number(Result1Bnb.result) / busd) + poolDataSeparatedBsc[3].total) * rOther?.find(iOther => iOther.id === "binancecoin")?.current_price)
      setTotalBalanceBnb5(((Number(Result1Bnb.result) / busd) + poolDataSeparatedBsc[4].total) * rOther?.find(iOther => iOther.id === "binancecoin")?.current_price)
      {
        const tokenContractBusd = new ethers.Contract(contractAddressBusdApprove, abiBusdApprove, provider)
        const Result1Busd = await tokenContractBusd.balanceOf(contractAddressBusd)
        setTotalBalanceBusd1(((Number(Result1Busd) / busd) + poolDataSeparatedBsc[5].total) * rOther?.find(iOther => iOther.id === "binance-usd")?.current_price)
        setTotalBalanceBusd2(((Number(Result1Busd) / busd) + poolDataSeparatedBsc[6].total) * rOther?.find(iOther => iOther.id === "binance-usd")?.current_price)
        setTotalBalanceBusd3(((Number(Result1Busd) / busd) + poolDataSeparatedBsc[7].total) * rOther?.find(iOther => iOther.id === "binance-usd")?.current_price)
        setTotalBalanceBusd4(((Number(Result1Busd) / busd) + poolDataSeparatedBsc[8].total) * rOther?.find(iOther => iOther.id === "binance-usd")?.current_price)
        setTotalBalanceBusd5(((Number(Result1Busd) / busd) + poolDataSeparatedBsc[9].total) * rOther?.find(iOther => iOther.id === "binance-usd")?.current_price)

        setTotalBalanceCake1(poolDataSeparatedBsc[10].total * rOther?.find(iOther => iOther.id === "pancakeswap-token")?.current_price)
        setTotalBalanceCake2(poolDataSeparatedBsc[11].total * rOther?.find(iOther => iOther.id === "pancakeswap-token")?.current_price)
        setTotalBalanceCake3(poolDataSeparatedBsc[12].total * rOther?.find(iOther => iOther.id === "pancakeswap-token")?.current_price)
        setTotalBalanceCake4(poolDataSeparatedBsc[13].total * rOther?.find(iOther => iOther.id === "pancakeswap-token")?.current_price)
        setTotalBalanceCake5(poolDataSeparatedBsc[14].total * rOther?.find(iOther => iOther.id === "pancakeswap-token")?.current_price)

        setTotalBalanceBaby1(poolDataSeparatedBsc[15].total * rOther?.find(iOther => iOther.id === "baby-doge-coin")?.current_price)
        setTotalBalanceBaby2(poolDataSeparatedBsc[16].total * rOther?.find(iOther => iOther.id === "baby-doge-coin")?.current_price)
        setTotalBalanceBaby3(poolDataSeparatedBsc[17].total * rOther?.find(iOther => iOther.id === "baby-doge-coin")?.current_price)
        setTotalBalanceBaby4(poolDataSeparatedBsc[18].total * rOther?.find(iOther => iOther.id === "baby-doge-coin")?.current_price)
        setTotalBalanceBaby5(poolDataSeparatedBsc[19].total * rOther?.find(iOther => iOther.id === "baby-doge-coin")?.current_price)

        setTotalBalanceTwt1(poolDataSeparatedBsc[20].total * rOther?.find(iOther => iOther.id === "trust-wallet-token")?.current_price)
        setTotalBalanceTwt2(poolDataSeparatedBsc[21].total * rOther?.find(iOther => iOther.id === "trust-wallet-token")?.current_price)
        setTotalBalanceTwt3(poolDataSeparatedBsc[22].total * rOther?.find(iOther => iOther.id === "trust-wallet-token")?.current_price)
        setTotalBalanceTwt4(poolDataSeparatedBsc[23].total * rOther?.find(iOther => iOther.id === "trust-wallet-token")?.current_price)
        setTotalBalanceTwt5(poolDataSeparatedBsc[24].total * rOther?.find(iOther => iOther.id === "trust-wallet-token")?.current_price)

        setTotalBalanceGmt1(poolDataSeparatedBsc[25].total * rOther?.find(iOther => iOther.id === "stepn")?.current_price)
        setTotalBalanceGmt2(poolDataSeparatedBsc[26].total * rOther?.find(iOther => iOther.id === "stepn")?.current_price)
        setTotalBalanceGmt3(poolDataSeparatedBsc[27].total * rOther?.find(iOther => iOther.id === "stepn")?.current_price)
        setTotalBalanceGmt4(poolDataSeparatedBsc[28].total * rOther?.find(iOther => iOther.id === "stepn")?.current_price)
        setTotalBalanceGmt5(poolDataSeparatedBsc[29].total  * rOther?.find(iOther => iOther.id === "stepn")?.current_price)

        setTotalBalanceCoin1(poolDataSeparatedBsc[30].total * rOther?.find(iOther => iOther.id === "coin98")?.current_price)
        setTotalBalanceCoin2(poolDataSeparatedBsc[31].total * rOther?.find(iOther => iOther.id === "coin98")?.current_price)
        setTotalBalanceCoin3(poolDataSeparatedBsc[32].total * rOther?.find(iOther => iOther.id === "coin98")?.current_price)
        setTotalBalanceCoin4(poolDataSeparatedBsc[33].total * rOther?.find(iOther => iOther.id === "coin98")?.current_price)
        setTotalBalanceCoin5(poolDataSeparatedBsc[34].total * rOther?.find(iOther => iOther.id === "coin98")?.current_price)
      }
    }).finally(() => setLoading(false))
  }

  const getTvlMoney = (token: string, index: number) => {
    if (token === "ETH" && index === 1 ) {
      return totalBalanceEth1
    }
    if (token === "ETH" && index === 2 ) {
      return totalBalanceEth2
    }
    if (token === "ETH" && index === 3 ) {
      return totalBalanceEth3
    }
    if (token === "ETH" && index === 4 ) {
      return totalBalanceEth4
    }
    if (token === "ARB" && index === 1 ) {
      return totalBalanceArb1
    }
    if (token === "ARB" && index === 2) {
      return totalBalanceArb2
    }
    if (token === "ARB" && index === 3) {
      return totalBalanceArb3
    }
    if (token === "ARB" && index === 4) {
      return totalBalanceArb4
    }
    if (token === "BNB" && index === 1) {
      return totalBalanceBnb1
    }
    if (token === "BNB" && index === 2) {
      return totalBalanceBnb2
    }
    if (token === "BNB" && index === 3) {
      return totalBalanceBnb3
    }
    if (token === "BNB" && index === 4) {
      return totalBalanceBnb4
    }
    if (token === "BNB" && index === 5) {
      return totalBalanceBnb5
    }
    if (token === "USDT" && index === 1) {
      return totalBalanceUsdt1
    }
    if (token === "USDT" && index === 2) {
      return totalBalanceUsdt2
    }
    if (token === "USDT" && index === 3) {
      return totalBalanceUsdt3
    }
    if (token === "USDT" && index === 4) {
      return totalBalanceUsdt4
    }
    if (token === "BUSD" && index === 1) {
      return totalBalanceBusd1
    }
    if (token === "BUSD" && index === 2) {
      return totalBalanceBusd2
    }
    if (token === "BUSD" && index === 3) {
      return totalBalanceBusd3
    }
    if (token === "BUSD" && index === 4) {
      return totalBalanceBusd4
    }
    if (token === "BUSD" && index === 5) {
      return totalBalanceBusd5
    }
    if (token === "USDC" && index === 1) {
      return totalBalanceUsdc1
    }
    if (token === "USDC" && index === 2) {
      return totalBalanceUsdc2
    }
    if (token === "USDC" && index === 3) {
      return totalBalanceUsdc3
    }
    if (token === "USDC" && index === 4) {
      return totalBalanceUsdc4
    }
    if (token === "CAKE" && index === 1) {
      return totalBalanceCake1
    }
    if (token === "CAKE" && index === 2) {
      return totalBalanceCake2
    }
    if (token === "CAKE" && index === 3) {
      return totalBalanceCake3
    }
    if (token === "CAKE" && index === 4) {
      return totalBalanceCake4
    }
    if (token === "CAKE" && index === 5) {
      return totalBalanceCake5
    }
    if (token === "UNI" && index === 1) {
      return totalBalanceUni1
    }
    if (token === "UNI" && index === 2) {
      return totalBalanceUni2
    }
    if (token === "UNI" && index === 3) {
      return totalBalanceUni3
    }
    if (token === "UNI" && index === 4) {
      return totalBalanceUni4
    }
    if (token === "BABYDOGE" && index === 1) {
      return totalBalanceBaby1
    }
    if (token === "BABYDOGE" && index === 2) {
      return totalBalanceBaby2
    }
    if (token === "BABYDOGE" && index === 3) {
      return totalBalanceBaby3
    }
    if (token === "BABYDOGE" && index === 4) {
      return totalBalanceBaby4
    }
    if (token === "BABYDOGE" && index === 5) {
      return totalBalanceBaby5
    }
    if (token === "WBTC" && index === 1&& chainId !== 42161) {
      return totalBalanceWbtc1
    }
    if (token === "WBTC" && index === 2&& chainId !== 42161) {
      return totalBalanceWbtc2
    }
    if (token === "WBTC" && index === 3&& chainId !== 42161) {
      return totalBalanceWbtc3
    }
    if (token === "WBTC" && index === 4&& chainId !== 42161) {
      return totalBalanceWbtc4
    }
    if (token === "WBTC" && index === 1 && chainId === 42161) {
      return totalBalanceWbtcArb1
    }
    if (token === "WBTC" && index === 2 && chainId === 42161) {
      return totalBalanceWbtcArb2
    }
    if (token === "WBTC" && index === 3 && chainId === 42161) {
      return totalBalanceWbtcArb3
    }
    if (token === "WBTC" && index === 4 && chainId === 42161) {
      return totalBalanceWbtcArb4
    }
    if (token === "TWT" && index === 1) {
      return totalBalanceTwt1
    }
    if (token === "TWT" && index === 2) {
      return totalBalanceTwt2
    }
    if (token === "TWT" && index === 3) {
      return totalBalanceTwt3
    }
    if (token === "TWT" && index === 4) {
      return totalBalanceTwt4
    }
    if (token === "TWT" && index === 5) {
      return totalBalanceTwt5
    }
    if (token === "APE" && index === 1) {
      return totalBalanceApe1
    }
    if (token === "APE" && index === 2) {
      return totalBalanceApe2
    }
    if (token === "APE" && index === 3) {
      return totalBalanceApe3
    }
    if (token === "APE" && index === 4) {
      return totalBalanceApe4
    }
    if (token === "APE" && index === 5) {
      return totalBalanceApe5
    }
    if (token === "GMT" && index === 1) {
      return totalBalanceGmt1
    }
    if (token === "GMT" && index === 2) {
      return totalBalanceGmt2
    }
    if (token === "GMT" && index === 3) {
      return totalBalanceGmt3
    }
    if (token === "GMT" && index === 4) {
      return totalBalanceGmt4
    }
    if (token === "GMT" && index === 5) {
      return totalBalanceGmt5
    }
    if (token === "LINK" && index === 1 && chainId !== 42161) {
      return totalBalanceLink1
    }
    if (token === "LINK" && index === 2 && chainId !== 42161) {
      return totalBalanceLink2
    }
    if (token === "LINK" && index === 3 && chainId !== 42161) {
      return totalBalanceLink3
    }
    if (token === "LINK" && index === 4 && chainId !== 42161) {
      return totalBalanceLink4
    }
    if (token === "LINK" && index === 1 && chainId === 42161) {
      return totalBalanceLinkArb1
    }
    if (token === "LINK" && index === 2 && chainId === 42161) {
      return totalBalanceLinkArb2
    }
    if (token === "LINK" && index === 3 && chainId === 42161) {
      return totalBalanceLinkArb3
    }
    if (token === "LINK" && index === 4 && chainId === 42161) {
      return totalBalanceLinkArb4
    }
    if (token === "C98" && index === 1) {
      return totalBalanceCoin1
    }
    if (token === "C98" && index === 2) {
      return totalBalanceCoin2
    }
    if (token === "C98" && index === 3) {
      return totalBalanceCoin3
    }
    if (token === "C98" && index === 4) {
      return totalBalanceCoin4
    }
    if (token === "C98" && index === 5) {
      return totalBalanceCoin5
    }
    return 0
  }
  const getAllInfo = () => {
    setOurTvlData(
      poolDataSeparated.map(poolItem => ({
        tvl: getTvlMoney(poolItem.token, poolItem.index),
        ...poolItem,
      })),
    )
  }

  const getAllInfoBnbFirst = () => {
    setOurTvlData(
      poolDataSeparatedBsc.map(poolItem => ({
        tvl: getTvlMoney(poolItem.token, poolItem.index),
        ...poolItem,
      })),
    )
  }

  useEffect(() => {
    // const data = JSON.stringify(ourTvlData)
    // const link = document.createElement('a')
    //
    // link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data))
    // link.setAttribute('download', 'filename' || 'data.json')
    // link.style.display = 'none'
    //
    // document.body.appendChild(link)
    //
    // link.click()
    // document.body.removeChild(link)

    // if (localStorage.getItem("swapData") !== null && chainId === 1) {
    //   return setOurTvlData(getFromLocalStorage("swapData"))
    // }
    // if (localStorage.getItem("swapDataBsc") !== null && chainId === 56) {
    //   return setOurTvlData(getFromLocalStorage("swapDataBsc"))
    // }

    // if (chainId === 56) {
    //   setOurTvlData(preloadDataBsc)
    // }
    // if (chainId === 1 || !address) {
    //   setOurTvlData(preloadData)
    // }
  }, [chainId])
  useEffect(() => {
    setLoading(true)
    if (chainId === 1 || !address) {
      getAllInfoTvl()
    }
    if (chainId === 56) {
      getAllInfoBnb()
    }
  }, [chainId, address])
  useEffect(() => {
    if ((chainId === 1 || !address) && totalBalanceApe5 > 0) {
      getAllInfo()
    }
    if (chainId === 56 && totalBalanceCoin5 > 0) {
      getAllInfoBnbFirst()
    }
  }, [totalBalanceApe5, chainId, totalBalanceCoin5, totalBalanceLinkArb4])
  useEffect(() => {
    // if (!!ourTvlData.length && ourTvlData?.[0]?.tvl > 0 && (chainId === 1 || !address)) {
    //   setToLocalStorage("swapData", ourTvlData)
    // }
    // if (!!ourTvlData.length && ourTvlData?.[0]?.tvl > 0 && chainId === 56) {
    //   setToLocalStorage("swapDataBsc", ourTvlData)
    // }
  }, [ourTvlData, chainId])

  return (
    <div className="swap" style={{ backgroundImage: `url(${imgMainBgSrc})` }}>
      <Header />
      {loading && <Loading />}
      <div className="swap-content">
        <div className="swap-content-header">
          <h3 className="swap-content-title">Analytics {` `}
            {(chainId === 1 || !address) && 'Ethereum'}
            {chainId === 56 && 'Binance Smart chainId'}
          </h3>
          <div className="pool-content-header-tabs">
            <button
              onClick={() => setTab("overview")}
              className={cn("pool-content-header-tabs-item", {
                active: tab === "overview",
              })}
            >
              Overview
            </button>
            <button
              onClick={() => setTab("pools")}
              className={cn("pool-content-header-tabs-item", {
                active: tab === "pools",
              })}
            >
              Pools
            </button>
            <button
              onClick={() => setTab("tokens")}
              className={cn("pool-content-header-tabs-item", {
                active: tab === "tokens",
              })}
            >
              Tokens
            </button>
          </div>
        </div>
        {tab === "overview" && (
          <>
            <div className="swap-content-subtitle">Top Tokens</div>
            <div className="swap-content-charts">
              <SwapChartRight
                total={ourTvlData.reduce((partialSum, a) => partialSum + Number(a.tvl), 0)}
              />
              {/*<TradeChart />*/}
            </div>
            <div className="swap-content-subtitle">Top Tokens</div>
            <SwapTable tokens={topTokens.map(token => ({
              ...token,
              market_cap: token?.symbol === 'weth' ? topTokens?.find(token => token.symbol === 'eth')?.market_cap : token?.market_cap,
            }))} onClick={v => navigate(`${routes.token}/${v}?${search.toString()}`)} />
            <div className="swap-content-subtitle">Top Pools</div>
            <SwapTablePairs
              onClick={res => navigate(`${routes.poolInfo}/${res}?${search.toString()}`)}
              stat={ourTvlData.sort((a, b) => Number(b.tvl) - Number(a.tvl))}
            />
          </>
        )}
        {tab === "pools" && (
          <>
            <div className="swap-content-subtitle">All Pools</div>
            <SwapTablePairs onClick={res => navigate(`${routes.poolInfo}/${res}?${search.toString()}`)} stat={ourTvlData} />
          </>
        )}
        {tab === "tokens" && (
          <>
            <div className="swap-content-header">
              <div className="swap-content-subtitle">Top Movers</div>
              <div className="swap-content-buttons">
                <button onClick={onPrevClick} disabled={translateX === 0} className="swap-content-buttons-item">
                  <Arrow />
                </button>
                <button onClick={onNextClick} className="swap-content-buttons-item">
                  <Arrow />
                </button>
              </div>
            </div>
            <div className="swap-content-scroll">
              <div className="swap-content-scroll-content" style={{ transform: `translateX(${translateX}px)` }}>
                {topTokens
                  .sort((a, b) => Number(b?.price_change_percentage_24h) - Number(a?.price_change_percentage_24h))
                  ?.map((mover, index) => (
                    <SwapMover
                      onClick={() => navigate(`${routes.token}/${mover.id}?${search.toString()}`)}
                      name={mover?.symbol.toUpperCase()}
                      key={index}
                      pricePercents={mover?.price_change_percentage_24h}
                      priceNow={mover?.current_price}
                      img={mover?.image}
                    />
                  ))}
              </div>
            </div>
            <div className="swap-content-subtitle">All Tokens</div>
            <SwapTable tokens={topTokens} onClick={v => navigate(`${routes.token}/${v}?${search.toString()}`)} />
          </>
        )}
      </div>
      {/*{connectModal && <ConnectModal onClose={() => setConnectModal(false)} />}*/}
      <Footer />
    </div>
  )
}
