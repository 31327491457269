import "./RefModal.scss"
import { SimpleButton } from "../../components"
import { copyToClipboard } from "../../utils"
import { useEffect } from "react"
import { ReactComponent as Close } from "assets/icons/close-modal.svg"
import { useAccount } from "wagmi";

type Props = {
  onClose: () => void
  users: any[]
}

export const RefModal = ({ onClose, users }: Props): JSX.Element => {
  const { address } = useAccount();
  const body = document.body

  useEffect(() => {
    body.style.overflow = "hidden"
  }, [body.style.overflow])

  const handleClose = () => {
    body.style.overflow = "scroll"
    onClose()
  }

  const getPercent = () => {
    switch (users?.length) {
      case 5:
        return '5'
      case 10:
        return '8'
      case 20:
        return '10'
      case 25:
        return '12'
      case 30:
        return '15'
      default:
        return '3'
    }
  }

  return (
    <div className="ref-modal-wrapper">
      <div className="ref-modal-content animate__animated animate__zoomIn animate__faster">
        <h2 className="ref-modal-content-title">My referral code</h2>
        <button className="ref-modal-content-close" onClick={handleClose}>
          <Close />
        </button>
        <div className="ref-modal-content-subtitle">You may refer your friends to receive more bonuses</div>
        <div className="ref-modal-content-block full">
          <div className="ref-modal-content-block-left">
            <div className="ref-modal-content-block-left-title">Your code</div>
            <div className="ref-modal-content-block-left-value big">
              https://ceo.finance/...{`${address?.slice(address?.length - 10, address?.length)}`}
            </div>
          </div>
          <SimpleButton
            onClick={() => {
              copyToClipboard(`https://ceo.finance/?ref=${address}` || "")
            }}
            text="Copy"
            variant="colored"
          />
        </div>
        <div className="ref-modal-content-flex">
          <div className="ref-modal-content-block">
            <div className="ref-modal-content-block-left">
              <div className="ref-modal-content-block-left-value">Total invited</div>
              <div className="ref-modal-content-block-left-title">Quantity</div>
            </div>
            <div className="ref-modal-content-block-place">{users?.length}</div>
          </div>
          <div className="ref-modal-content-block">
            <div className="ref-modal-content-block-left">
              <div className="ref-modal-content-block-left-value">Your Bonus</div>
            </div>
            <div className="ref-modal-content-block-desc">{getPercent() || 3}% of Ceo Finance&apos;s commission</div>
          </div>
        </div>
        {!!users?.length && (
          <div className="ref-modal-content-block full">
            <div className="ref-modal-content-block-left">
              <div className="ref-modal-content-block-left-title">Your invited users:</div>
              {users?.map((i, index) => (
                <div className="ref-modal-content-block-left-value big" key={index}>
                  {i.follower}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
