import React, { useEffect, useState } from "react"

import imgMainSrc from "assets/images/main.webp"
import "./Main.scss"
import { Link, useSearchParams } from "react-router-dom"
import { getFromLocalStorage, mixins, moneyFormatter, routes, setToLocalStorage } from "../../utils"
import { useMedia } from "use-media"

type Props = {
  onEndLoad: () => void
}

export const Main = ({ onEndLoad }: Props): JSX.Element => {
  const [search] = useSearchParams()
  const isM = useMedia({ maxWidth: 1200 })
  const busd = 1000000000000000000

  return (
    <main className="main">
      <div className="main-left">
        <div className="main-left-first">Amplify Your Cross-Chain Staking</div>
        <h1 className="main-left-title">
          Experience <span className="colored">Ceo Finance</span>, your gateway to unbounded earnings in decentralized staking, powered by our robust smart contracts
        </h1>
        <p className="main-left-desc">Stake, Earn, Soar with Ceo Finance.</p>
        <div className="main-left-buttons">
          <Link to={`${routes.farms}?${search.toString()}`} className="main-left-buttons-item">
            Stake NOW
          </Link>
        </div>
      </div>
      <div className="main-right">
        <img onLoad={onEndLoad} src={imgMainSrc} alt="main" className="main-right-img" />
        <div className="main-right-numbers">
        </div>
      </div>
    </main>
  )
}
