export const refData = [
  { account: "0xa27135126e137e3c****212240a1ffbf8d979de1", referrals: 68.55123199771236, money: 67970.35355013132 },
  { account: "0xb29c5c63c6f4e7c0****2dc1ae110b4f1806023c", referrals: 37.71489973828042, money: 16086.591807641085 },
  { account: "0x3ce9eb4de6315809****4ac6d7f62791f088a5a6", referrals: 53.500345527968854, money: 31333.02250390681 },
  { account: "0x39c4b02dc9d33b9d****d4e5232cd65e834f8ab0", referrals: 22.62476144351313, money: 33328.8143878558 },
  { account: "0x70e66ec7fc350409****6f47083e663b249cded7", referrals: 39.3027386742502, money: 56489.99159447542 },
  { account: "0xe04b9157bcf2701c****a439c060284c3a11e8a4", referrals: 61.01777490969604, money: 40901.89143660249 },
  { account: "0xb10e7fb3b1535af2****50d56517159d0486c0af", referrals: 57.85533247344429, money: 43400.37937170555 },
  { account: "0x8ed452987b097792****315526705ee315b2d5a6", referrals: 60.41316598071053, money: 42596.338510890695 },
  { account: "0x1c1c30413b818cf4****db7c44c3f2b34a145085\n", referrals: 64.94559544631551, money: 48131.2650445031 },
  { account: "0x351df9f949f40f0f****5ff1b7d0ea5f89c3bde1\n", referrals: 42.035686707064514, money: 50776.47478001991 },
  { account: "0x90f06d21136ac043****b1b5b15e29c5c4e29520\n", referrals: 33.38772281644749, money: 21697.712020665156 },
  { account: "0x01b0ee840a11ff7d****df46dd6d9b38b19280df\n", referrals: 44.292545363614366, money: 33666.96854753832 },
  { account: "0xf660b22ffdf24286****67ec3cb6a1e8100b6c67\n", referrals: 66.34979308546176, money: 64228.70768375666 },
  { account: "0x3f029b2810205ce1****477d5e088c38599af11c\n", referrals: 46.281562891190376, money: 15702.158202249457 },
  { account: "0xafded5adf6adcf28****c7204715f84e083f004a\n", referrals: 50.19318687003977, money: 33488.7976519349 },
  { account: "0xc2b5cb8eb26cea08****5785422069c2bd831208\n", referrals: 48.113374069691844, money: 5291.880685648627 },
  { account: "0x651b243023c035a2****4c23f72af9546314134b\n", referrals: 54.55111923337418, money: 47564.85007652018 },
  { account: "0x0b7d9e436977997f****03e4f25b16977a8f1101\n", referrals: 58.9721935896652, money: 68303.4473951277 },
  { account: "0xc25f52e31c201e2d****6209d8a6b3fbae6a2006\n", referrals: 36.88148749252227, money: 42627.567737790836 },
  { account: "0xa147139eeb38414d****0dde5d538d26f48d8fdd\n", referrals: 28.021801351753865, money: 21076.85773963985 },
  { account: "0x7827b167b4e7f2bf****66fb516a14f9e90fb25f\n", referrals: 22.27129301462925, money: 60823.44718501428 },
  { account: "0x5787254f9d76cbcc****8139ba2dc4d9a74b6202\n", referrals: 51.192976559348224, money: 29599.890280482494 },
  { account: "0x787ffea94e817072****465b7747105f44689937\n", referrals: 41.4583746565897, money: 51136.50223882021 },
  { account: "0x0b7e7ea1e06d14b7****a07e8da50165df9ba4e6\n", referrals: 48.21005809591043, money: 28758.115522876178 },
  { account: "0x64ce429c07fdf0f1****8e9cdf3a364558e8f0f5\n", referrals: 58.837403205536525, money: 63654.8746712781 },
  { account: "0x5474e3cfda610426****fd5879c60424d0c985e5\n", referrals: 57.178353924542726, money: 43036.20018300657 },
  { account: "0x1c4ec875ba4ab3b6****c71f650beb876ddaef55\n", referrals: 45.01123498398219, money: 24686.380920737894 },
  { account: "0xd9af8037b6905999****42b5d2bd0dd7559595b1\n", referrals: 43.52566361984037, money: 28339.507050752065 },
  { account: "0x055b75f46aa93cdd****a5abdbb12758f8aeb3c0\n", referrals: 61.564298603499545, money: 57843.18429400249 },
  { account: "0x68af601506ddb568****c9659318773f8734b6d6\n", referrals: 58.98336800077004, money: 15308.930460850768 },
  { account: "0xa0ee57237601e130****8efdd3ec1e389e0c635e\n", referrals: 23.341151156410575, money: 44784.73771472545 },
  { account: "0x51022fc578393a48****11471ee9fcce6d7d5f9b\n", referrals: 65.0497900728434, money: 13067.894358715554 },
  { account: "0xc95ee4c8aa1e810d****da4bc1961eedb8af72f4\n", referrals: 62.75311742023387, money: 12844.128042112363 },
  { account: "0x451b88438cb1462c****984495ee11596fb0c15a\n", referrals: 57.06891699512199, money: 10401.484541598893 },
  { account: "0x230367d84c3da5e9****9cec9b511fd2e5a7a2e4\n", referrals: 52.57899385176681, money: 61847.921734074116 },
  { account: "0x69231af0f964f2bb****cc659bd39205463e8a95\n", referrals: 51.40846381591892, money: 16834.641700253334 },
  { account: "0x537f67a511fde4d6****1399daeeb767d668b9af\n", referrals: 41.64420950635084, money: 9864.767315435503 },
  { account: "0x1cdb89879fc60cd7****9c1d3a835ddf18249832\n", referrals: 36.744525833852194, money: 36001.919497664945 },
  { account: "0x1c811b0f5cbd19dd****2f0a2d7e65b2573e2c22\n", referrals: 23.874581711330595, money: 38001.741641214125 },
  { account: "0x013f439bed411e87****64bd3f29f976dfa705da\n", referrals: 64.38655031108874, money: 44131.33995063805 },
  { account: "0xab39f982a2068adf****cb855c9905a0843086db\n", referrals: 62.87766785323507, money: 50766.64191651681 },
  { account: "0xdbb3c1613e3a5683****609ef49458224c221150\n", referrals: 29.500458882299768, money: 60034.13927979951 },
  { account: "0xa5db7358771f34e9****fdf03388665f0f84aa11\n", referrals: 69.93260967829117, money: 53811.64797667723 },
  { account: "0x7a9041d74f7184d2****ffed1fdbec62ffa90213\n", referrals: 21.541917994190772, money: 44817.83375427767 },
  { account: "0xa8e2a9ea4323a22f****43c811003436c33c4a0d\n", referrals: 25.780585584154732, money: 4346.516186392954 },
  { account: "0xb4611545484d7208****d8db00c56a8ccd8274f6\n", referrals: 37.71676284746215, money: 61798.34322579175 },
  { account: "0x265ccabf6b197fd9****08865ec63c6a63bbd171\n", referrals: 42.272296709160656, money: 55309.01026207171 },
  { account: "0x9eb5d6f60b0e061f****f438da1bdf20d9effa3f\n", referrals: 29.82323071826785, money: 25741.177873462082 },
  { account: "0xba64a5d9f55d55bc****313785f131d4c6476efa\n", referrals: 21.26034754211566, money: 15636.136434451348 },
  { account: "0xa1a47ab00232b161****b478db5e0600053d46f1\n", referrals: 45.95376431103153, money: 26599.99375384173 },
  { account: "0xa27135126e137e3c****212240a1ffbf8d979de1\n", referrals: 44.301705260540196, money: 29218.98555849778 },
  { account: "0xb29c5c63c6f4e7c0****2dc1ae110b4f1806023c\n", referrals: 30.278677132859144, money: 16636.00800105618 },
  { account: "0x3ce9eb4de6315809****4ac6d7f62791f088a5a6\n", referrals: 35.22734247865328, money: 30772.085375264305 },
  { account: "0x39c4b02dc9d33b9d****d4e5232cd65e834f8ab0\n", referrals: 48.45942329610493, money: 54504.19830005476 },
  { account: "0x70e66ec7fc350409****6f47083e663b249cded7\n", referrals: 30.37673028875043, money: 6040.671642238561 },
  { account: "0xe04b9157bcf2701c****a439c060284c3a11e8a4\n", referrals: 53.74925755252762, money: 59040.09590220379 },
  { account: "0xb10e7fb3b1535af2****50d56517159d0486c0af\n", referrals: 38.034421124002975, money: 38715.23472829116 },
  { account: "0x8ed452987b097792****315526705ee315b2d5a6\n", referrals: 40.81656977090847, money: 40047.11688763064 },
  { account: "0x1c1c30413b818cf4****db7c44c3f2b34a145085\n", referrals: 28.12221795341335, money: 55979.2580130665 },
  { account: "0x351df9f949f40f0f****5ff1b7d0ea5f89c3bde1\n", referrals: 45.80650486849116, money: 25828.70415788139 },
  { account: "0x90f06d21136ac043****b1b5b15e29c5c4e29520\n", referrals: 25.835588527506026, money: 38847.44644451422 },
  { account: "0x01b0ee840a11ff7d****df46dd6d9b38b19280df\n", referrals: 42.97120316047992, money: 61488.45052980328 },
  { account: "0xf660b22ffdf24286****67ec3cb6a1e8100b6c67\n", referrals: 46.55856486092911, money: 48402.60873454227 },
  { account: "0x3f029b2810205ce1****477d5e088c38599af11c\n", referrals: 21.522682354400487, money: 21725.652266148853 },
  { account: "0xafded5adf6adcf28****c7204715f84e083f004a\n", referrals: 59.58788991563888, money: 42618.0076588522 },
  { account: "0xc2b5cb8eb26cea08****5785422069c2bd831208\n", referrals: 44.0003812880617, money: 31055.36455642109 },
  { account: "0x651b243023c035a2****4c23f72af9546314134b\n", referrals: 31.44503546651194, money: 7862.895236964542 },
  { account: "0x0b7d9e436977997f****03e4f25b16977a8f1101\n", referrals: 35.43294964178876, money: 55693.86407262584 },
  { account: "0xc25f52e31c201e2d****6209d8a6b3fbae6a2006\n", referrals: 44.529907533254104, money: 7924.888215505724 },
  { account: "0xa147139eeb38414d****0dde5d538d26f48d8fdd\n", referrals: 37.519269683654784, money: 15745.047682181035 },
  { account: "0x7827b167b4e7f2bf****66fb516a14f9e90fb25f\n", referrals: 31.958464901901102, money: 62374.54271451828 },
  { account: "0x5787254f9d76cbcc****8139ba2dc4d9a74b6202\n", referrals: 44.180259726405524, money: 20182.752539538968 },
  { account: "0x787ffea94e817072****465b7747105f44689937\n", referrals: 38.4176789554118, money: 66142.64784091715 },
  { account: "0x0b7e7ea1e06d14b7****a07e8da50165df9ba4e6\n", referrals: 50.83919456342355, money: 16597.16847071277 },
  { account: "0x64ce429c07fdf0f1****8e9cdf3a364558e8f0f5\n", referrals: 63.86228045451886, money: 15091.698453073284 },
  { account: "0x5474e3cfda610426****fd5879c60424d0c985e5\n", referrals: 55.607528537041844, money: 29933.785869894757 },
  { account: "0x1c4ec875ba4ab3b6****c71f650beb876ddaef55\n", referrals: 69.82682536091164, money: 12437.219972526615 },
  { account: "0xd9af8037b6905999****42b5d2bd0dd7559595b1\n", referrals: 47.81859465156269, money: 25005.973314717194 },
  { account: "0x055b75f46aa93cdd****a5abdbb12758f8aeb3c0\n", referrals: 45.96865771712702, money: 28435.344471675253 },
  { account: "0x68af601506ddb568****c9659318773f8734b6d6\n", referrals: 43.079381319126156, money: 16966.798736852194 },
  { account: "0xa0ee57237601e130****8efdd3ec1e389e0c635e\n", referrals: 23.890354020260233, money: 5709.586989280557 },
  { account: "0x51022fc578393a48****11471ee9fcce6d7d5f9b\n", referrals: 23.795262789409843, money: 37808.34365817866 },
  { account: "0xc95ee4c8aa1e810d****da4bc1961eedb8af72f4\n", referrals: 36.120958035619616, money: 61090.89564201703 },
  { account: "0x451b88438cb1462c****984495ee11596fb0c15a\n", referrals: 68.90056135567866, money: 64500.73609658976 },
  { account: "0x230367d84c3da5e9****9cec9b511fd2e5a7a2e4\n", referrals: 38.287003674257114, money: 10076.044297753737 },
  { account: "0x69231af0f964f2bb****cc659bd39205463e8a95\n", referrals: 24.88479885884399, money: 12071.220067969598 },
  { account: "0x537f67a511fde4d6****1399daeeb767d668b9af\n", referrals: 61.70143904765016, money: 9432.364204263758 },
  { account: "0x1cdb89879fc60cd7****9c1d3a835ddf18249832\n", referrals: 56.979465252647, money: 16203.490575835169 },
  { account: "0x1c811b0f5cbd19dd****2f0a2d7e65b2573e2c22\n", referrals: 40.722717346054, money: 50361.37001391268 },
  { account: "0x013f439bed411e87****64bd3f29f976dfa705da\n", referrals: 56.1424527069515, money: 39979.177499088815 },
  { account: "0xab39f982a2068adf****cb855c9905a0843086db\n", referrals: 30.04652760937612, money: 65360.37198061385 },
  { account: "0xdbb3c1613e3a5683****609ef49458224c221150\n", referrals: 64.0775637867932, money: 57440.86932058811 },
  { account: "0xa5db7358771f34e9****fdf03388665f0f84aa11\n", referrals: 30.345968105478317, money: 33680.43563086908 },
  { account: "0x7a9041d74f7184d2****ffed1fdbec62ffa90213\n", referrals: 42.11401810939334, money: 19112.03977658799 },
  { account: "0xa8e2a9ea4323a22f****43c811003436c33c4a0d\n", referrals: 33.77977302565353, money: 19717.35163635297 },
  { account: "0xb4611545484d7208****d8db00c56a8ccd8274f6\n", referrals: 61.20208065417237, money: 55342.672134894834 },
  { account: "0x265ccabf6b197fd9****08865ec63c6a63bbd171\n", referrals: 66.05271920702663, money: 43683.31298599534 },
  { account: "0x9eb5d6f60b0e061f****f438da1bdf20d9effa3f\n", referrals: 40.49232743988556, money: 9357.440883168427 },
  { account: "0xba64a5d9f55d55bc****313785f131d4c6476efa\n", referrals: 23.926547625749784, money: 26905.31936646928 },
  { account: "0xa1a47ab00232b161****b478db5e0600053d46f1\n", referrals: 24.638449360323534, money: 53569.947764323864 },
];
