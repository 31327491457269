import React, { useEffect, useState } from "react"
import { Header, Footer, RefMain, RefBlocks, RefFAQ, RefTable, RefWorks, Loading } from "components"
import {  RefModal } from "../../modals"

import "./Referral.scss"
import {useAccount} from "wagmi";
import {getChainId} from "@wagmi/core";
import {config} from "../../index";
import {useModal} from 'connectkit'
import imgMainBgSrc from "assets/images/main-bg.webp"

export const Referral = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false)
  const [ref, setRef] = useState(false)
  const [refUsers, setRefUsers] = useState<any[]>([])
  const { address } = useAccount();
  const chainId = getChainId(config)
  const {setOpen} = useModal()

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [])

  return (
    <div className="referral-page" style={{ backgroundImage: `url(${imgMainBgSrc})` }}>
      {!loaded && <Loading />}
      <Header />
      <RefMain openModal={() => {
        if (!address) {
          setOpen(true)
          return
        }
        setRef(true)
      }} onEndLoad={() => setLoaded(true)} />
      <RefBlocks />
      <RefWorks />
      <RefTable />
      <RefFAQ />
      {ref && <RefModal users={refUsers} onClose={() => setRef(false)} />}
      <Footer />
    </div>
  )
}
