import React from "react"

import img1 from "assets/icons/partners/1.svg"
import img2 from "assets/icons/partners/2.svg"
import img3 from "assets/icons/partners/3.svg"
import img5 from "assets/icons/partners/5.svg"
import img6 from "assets/icons/partners/6.svg"
import img7 from "assets/icons/partners/7.png"
import img9 from "assets/icons/partners/9.svg"
import "./Partners.scss"

export const Partners = (): JSX.Element => {
  const partners = [
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img3,
    },
    {
      img: img5,
    },
    {
      img: img6,
    },
    {
      img: img7,
    },
    {
      img: img9,
    },
  ]
  return (
    <div className="partners">
      <h3 className="partners-title">Working together with partners</h3>
      <p className="partners-desc">
        Partners from various industries trust us and help us to create a reliable ecosystem that benefits both
        individuals and businesses.
      </p>
      <div className="partners-content">
        {partners.map((block, index) => (
          <img src={block.img} alt="partner" key={index} className="partners-content-item" />
        ))}
      </div>
    </div>
  )
}
