import React, { useEffect, useState } from "react"
import {
  Header,
  Footer,
  Main,
  Features,
  Ecosystem,
  Loading,
  Partners,
  Investors,
} from "components"

import "./Home.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"
import imgBgSrc from "assets/images/back-footer.webp"

export const Home = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false)

  return (
    <div className="home" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      {!loaded && <Loading/>}
      <Header/>
      <Main onEndLoad={() => setLoaded(true)}/>
      <Features/>
      <Partners/>
      <Investors/>
      <Ecosystem/>
      <Footer/>
      <img loading='lazy' src={imgBgSrc} alt="background" className="home-bg-footer"/>

    </div>
  )
}
