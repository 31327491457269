import { HttpClient } from "./http-client";

const apiUrl = "https://api.bscscan.com";
if (!apiUrl) {
  throw new Error("API URL env must be set!");
}

export class Api extends HttpClient {
  public constructor() {
    super(apiUrl as string);
  }
  public getBalance = (address: string) => {
    return this.instance.get<any[], any>(
      `/api?module=account&action=balance&address=${address}`,
    );
  };

  fetch() {
    return this.instance.get("");
  }
}

const apiScan = new Api();

export { apiScan };
