import { useEffect, useRef, useState } from "react"
import cn from "classnames"
import Web3 from "web3"
import { Link, useSearchParams } from "react-router-dom"
import { getGasPrice } from '@wagmi/core'
import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down.svg"
import { ReactComponent as Export } from "assets/icons/export.svg"
import { ReactComponent as Metamask } from "assets/icons/metamask.svg"
import "./CollapseTable.scss"
import { SimpleButton } from "../SimpleButton"
import {
  contractAddressUsdt,
  approveAddress,
  contractAddressEth,
  contractAddressUsdc,
  contractAddressUsdcApprove,
  contractAddressUni,
  contractAddressUniApprove,
  contractAddressLink,
  contractAddressLinkApprove,
  contractAddressWbtcApprove,
  contractAddressApe,
  contractAddressApeApprove,
  contractAddressBusdApprove,
  contractAddressCakeApprove,
  contractAddressBabyDogeApprove,
  contractAddressBnb,
  contractAddressBusd,
  contractAddressWbtc,
  contractAddressLinkArbitrum,
  contractAddressLinkArbitrumApprove,
  contractAddressWbtcArbitrum,
  contractAddressWbtcArbitrumApprove, contractAddressArbArbitrum, contractAddressArbArbitrumApprove,
} from "../../abi"
import abi from "../../abi/abi.json"
import abiEth from "../../abi/abiEth.json"
import abiBnb from "../../abi/abiBnb.json"
import abiBusd from "../../abi/abiBusd.json"
import abiUsdc from "../../abi/abiUsdc.json"
import abiUsdcApprove from "../../abi/abiUsdcApprove.json"
import abiApprove from "../../abi/abiApprove.json"
import abiUni from "../../abi/abiUni.json"
import abiUniApprove from "../../abi/abiUniApprove.json"
import abiLink from "../../abi/abiLink.json"
import abiLinkApprove from "../../abi/abiLinkApprove.json"
import abiWbtc from "../../abi/abiWbtc.json"
import abiWbtcApprove from "../../abi/abiWbtcApprove.json"
import abiApe from "../../abi/abiApe.json"
import abiApeApprove from "../../abi/abiApeApprove.json"
import abiBabyDogeApprove from "../../abi/abiBabyDogeApprove.json"
import abiBusdApprove from "../../abi/abiBusdApprove.json"
import abiCakeApprove from "../../abi/abiCakeApprove.json"
import abiArb from "../../abi/abiArb.json"
import abiArbApprove from "../../abi/abiArbApprove.json"
import abiStandartArbApprove from "../../abi/abiArbitrumStandartApprove.json"
import { Input } from "../Input"
import { ethers } from "ethers"
import { connectToToken } from "../../utils/connectToToken"
import {getFromLocalStorage, mixins, moneyFormatter, routes, setToLocalStorage} from "../../utils"
import { api } from "../../service/api/api"
import {useMedia} from "use-media";
import { apiScan } from "../../service/api/apiScan"
import toast from "react-hot-toast";
import {apiBeaconcha} from "../../service/api/apiBeaconcha";
import {CollapseTableWithdrawal} from "../CollapseTableWithdrawal";
import {useAccount, useWalletClient} from "wagmi";
import { getChainId } from '@wagmi/core'
import {config} from "../../index";
import {walletClientToSigner} from "../../utils/walletToProvider";

type Props = {
  height?: number
  opened?: boolean
  quantity: string
  plan: string
  checkAddress: string
  img: string
  token: string
  percent: string
  stakedDisplay: boolean
  earn?: boolean
  stat: any
  id: string
  addAddress?: string
  day: string
  index: number
  isNew?: boolean
  openCancel: () => void
  openTransfer: (current: string, money: number, interests: number, length: number, allRecords: string[]) => void
  openClaimAnn: () => void
  total: number
}


export const CollapseTable = ({
                                opened = false,
                                height = 48,
                                quantity,
                                plan,
                                checkAddress,
                                img,
                                token,
                                percent,
                                stakedDisplay,
                                stat,
                                id,
                                day,
                                index,
                                addAddress,
                                isNew = true,
                                openCancel,
                                openTransfer,
                                openClaimAnn,
  total,
                              }: Props): JSX.Element => {
  const [search] = useSearchParams()
  const isM = useMedia({ maxWidth: mixins.m })
  const titleRef = useRef<any>(null)
  const [isOpen, setIsOpen] = useState(opened)
  const [approved, setApproved] = useState(0)
  const [input, setInput] = useState("")
  const [timeleftOther, setTimeLeftOther] = useState("0")
  const [withdrawalTotal, setWithdrawalTotal] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const { address, connector } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null
  const [interestNotCollected, setInterestNotCollected] = useState(0)
  const [remainingTime, setRemainingTime] = useState('')
  const [resultArray, setResultArray] = useState<any[]>([])
  const [disableClaim, setDisableClaim] = useState(false)
  const [change, setChange] = useState(false)
  const [allRecordsForModal, setAllRecordsForModal] = useState([])
  const busd = 1000000000000000000
  const chainId = getChainId(config)

  useEffect(() => {
    if (opened !== undefined) {
      setIsOpen(opened)
    }
  }, [opened])
  useEffect(() => {
    getAllInfo()
  }, [address, chainId])

  const getPlan = () => {
    if (token === "ETH" || token === "USDT" || token === 'BNB' || token === 'WBTC' || token === 'UNI') {
      if (plan === "7") {
        return 604800
      }
      if (plan === "14") {
        return 1209600
      }
      if (plan === "30") {
        return 2592000
      }
      if (plan === "60") {
        return 5184000
      }
      if (plan === "90") {
        return 7776000
      }
    }
    if (plan === "1") {
      return 86400
    }
    if (plan === "2") {
      return 172800
    }
    if (plan === "3") {
      return 259200
    }
    if (plan === "4") {
      return 345600
    }
    if (plan === "5") {
      return 432000
    }
  }

  const getPercent = () => {
    if (plan === '14') {
      return 0.8
    }
    if (plan === '30') {
      return 2
    }
    if (plan === '60') {
      return 5
    }
    if (plan === '90') {
      return 8.5
    }
    return 0.8
  }

  const getAllInfo = async () => {
    if (token === 'ETH') {
      api.getBalance(contractAddressEth).then(r => {
        if (!isNaN(Number(r.result))) {
          setTotalBalance(total + Number(r.result) / busd)
        }
      })
    }
    if (token === "ETH" && address !== undefined && chainId === 1) {

      // @ts-ignore
      const { ethereum } = window
      // @ts-ignore
      const provider = new ethers.providers.Web3Provider(ethereum)

      const nftContract = new ethers.Contract(contractAddressEth, abiEth, provider)
      if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
        setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
      }
      const depositStatusDataLol = await nftContract.getDepositInfo(address)

      const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
        depositIndices: Number(depositStatusDataLol.depositIndices[index]),
        stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
        lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
        unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
        id: index,
      }))
      setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

      setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

      const indexResult = result
        .filter(i => i.lockupPeriods === getPlan())
      let resultFinal = 0;
      indexResult.every(async (iResult) => {
        const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
        resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
        setInterestNotCollected(resultFinal)
      })
    }
    if (token === "BNB") {
      apiScan.getBalance(contractAddressBnb).then(r => {
        setTotalBalance(total + Number(r.result) / busd)
      })

      // @ts-ignore
      const { ethereum } = window
      // @ts-ignore
      const provider = new ethers.providers.Web3Provider(ethereum)

      const nftContract = new ethers.Contract(contractAddressBnb, abiBnb, provider)
      if (localStorage.getItem("bnbResult") !== null) {
        setResultArray(getFromLocalStorage("bnbResult"))
      }
      const depositStatusDataLol = await nftContract.getDepositInfo(address)
      const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
        depositIndices: Number(depositStatusDataLol.depositIndices[index]),
        stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
        lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
        unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
        id: index,
      }))
      setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
      setToLocalStorage("bnbResult", result.filter(i => i.lockupPeriods === getPlan()) || [])

      const indexResult = result
        .filter(i => i.lockupPeriods === getPlan())
      let resultFinal = 0;
      indexResult.every(async (iResult) => {
        const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
        resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
        setInterestNotCollected(resultFinal)
      })
    }

    if (token === "USDT") {
      {
// @ts-ignore
        const { ethereum } = window
        // @ts-ignore
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressUsdt, abi, provider)

        const tokenContract = new ethers.Contract(approveAddress, abiApprove, provider)
        // if (localStorage.getItem("usdtBalance") !== null) {
        //   setTotalBalance(getFromLocalStorage("usdtBalance"))
        // }
        const balanceData = await tokenContract.balanceOf(contractAddressUsdt)
        setTotalBalance(total + Number(balanceData) / 1000000)
        // setToLocalStorage("usdtBalance", Number(balanceData) / 1000000)

        const approvedData = await tokenContract.allowance(address, contractAddressUsdt)

        if (localStorage.getItem(`usdtResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`usdtResult${plan}`))
        }

        setApproved(Number(approvedData) / 1000000)
        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`usdtResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)
          setInterestNotCollected(resultFinal)
        })
      }
    }
    if (token === "USDC" && (chainId === 1 || !address)) {
      {
// @ts-ignore
        const { ethereum } = window
        // @ts-ignore
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressUsdc, abiUsdc, provider)

        const tokenContract = new ethers.Contract(contractAddressUsdcApprove, abiUsdcApprove, provider)
        // if (localStorage.getItem("usdcBalance") !== null) {
        //   setTotalBalance(getFromLocalStorage("usdcBalance"))
        // }
        const balanceData = await tokenContract.balanceOf(contractAddressUsdc)
        setTotalBalance(total + Number(balanceData) / 1000000)
        setToLocalStorage("usdcBalance", Number(balanceData) / 1000000)

        const approvedData = await tokenContract.allowance(address, contractAddressUsdc)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem("usdcResult") !== null) {
          setResultArray(getFromLocalStorage("usdcResult"))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage("usdcResult", result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
          .reduce((accumulator, object) => {
            return accumulator + object.depositIndices
          }, 0)
        const depositStatusOtherData = await nftContract.calculateInterest(address, indexResult)
        setInterestNotCollected(
          !!result.filter(i => i.lockupPeriods === getPlan())?.length ? Number(depositStatusOtherData) / busd : 0,
        )
      }
    }
    if (token === "UNI" && (chainId || !address)) {
      {// @ts-ignore
        const { ethereum } = window
        // @ts-ignore
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressUni, abiUni, provider)

        const tokenContract = new ethers.Contract(contractAddressUniApprove, abiUniApprove, provider)
        // if (localStorage.getItem(`uniBalance${plan}`) !== null) {
        //   setTotalBalance(getFromLocalStorage(`uniBalance${plan}`))
        // }
        const balanceData = await tokenContract.balanceOf(contractAddressUni)
        setTotalBalance(total + Number(balanceData) / busd)
        // setToLocalStorage(`uniBalance${plan}`, Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressUni)

        setApproved(Number(approvedData) / busd)
        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`uniResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
          setInterestNotCollected(resultFinal)
        })

      }
    }
    if (token === "LINK" && chainId !== 42161) {
      {// @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressLink, abiLink, provider)

        const tokenContract = new ethers.Contract(contractAddressLinkApprove, abiLinkApprove, provider)

        const balanceData = await tokenContract.balanceOf(contractAddressLink)
        setTotalBalance(total + Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressLink)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`linkResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`linkResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`linkResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
          .reduce((accumulator, object) => {
            return accumulator + object.depositIndices
          }, 0)
        const depositStatusOtherData = await nftContract.calculateInterest(address, indexResult)
        setInterestNotCollected(
          !!result.filter(i => i.lockupPeriods === getPlan())?.length ? Number(depositStatusOtherData) / busd : 0,
        )
      }
    }
    if (token === "LINK" && chainId === 42161) {
      {// @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressLinkArbitrum, abiLink, provider)

        const tokenContract = new ethers.Contract(contractAddressLinkArbitrumApprove, abiStandartArbApprove, provider)

        const balanceData = await tokenContract.balanceOf(contractAddressLinkArbitrum)
        setTotalBalance(total + Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressLinkArbitrum)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`linkResultArb${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`linkResultArb${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`linkResultArb${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
          .reduce((accumulator, object) => {
            return accumulator + object.depositIndices
          }, 0)
        const depositStatusOtherData = await nftContract.calculateInterest(address, indexResult)
        setInterestNotCollected(
          !!result.filter(i => i.lockupPeriods === getPlan())?.length ? Number(depositStatusOtherData) / busd : 0,
        )
      }
    }
    if (token === "WBTC" && chainId !== 42161) {
      {// @ts-ignore
        const { ethereum } = window
// @ts-ignore
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressWbtc, abiWbtc, provider)
        const tokenContract = new ethers.Contract(contractAddressWbtcApprove, abiWbtcApprove, provider)

        // if (localStorage.getItem(`wbtcBalance${plan}`) !== null) {
        //   setTotalBalance(getFromLocalStorage(`wbtcBalance${plan}`))
        // }
        const balanceData = await tokenContract.balanceOf(contractAddressWbtc)
        setTotalBalance(total + Number(balanceData) / 100000000)
        // setToLocalStorage(`wbtcBalance${plan}`, Number(balanceData) / 100000000)

        const approvedData = await tokenContract.allowance(address, contractAddressWbtc)
        setApproved(Number(approvedData) / 100000000)
        if (localStorage.getItem(`wbtcResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`wbtcResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`wbtcResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / 100000000)
          setInterestNotCollected(resultFinal)
        })
      }
    }
    if (token === "WBTC" && chainId === 42161) {
      {// @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressWbtcArbitrum, abiWbtc, provider)
        const tokenContract = new ethers.Contract(contractAddressWbtcArbitrumApprove, abiStandartArbApprove, provider)

        const balanceData = await tokenContract.balanceOf(contractAddressWbtcArbitrum)
        setTotalBalance(total + Number(balanceData) / 100000000)

        const approvedData = await tokenContract.allowance(address, contractAddressWbtcArbitrum)
        setApproved(Number(approvedData) / 100000000)
        if (localStorage.getItem(`wbtcResultArb${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`wbtcResultArb${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`wbtcResultArb${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / 100000000)
          setInterestNotCollected(resultFinal)
        })
      }
    }
    if (token === "ARB" && chainId === 42161) {
      {// @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressArbArbitrum, abiArb, provider)
        const tokenContract = new ethers.Contract(contractAddressArbArbitrumApprove, abiArbApprove, provider)

        const balanceData = await tokenContract.balanceOf(contractAddressArbArbitrum)
        setTotalBalance(total + (Number(balanceData) / busd))

        const approvedData = await tokenContract.allowance(address, contractAddressArbArbitrum)
        setApproved(Number(approvedData) / busd)

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
          setInterestNotCollected(resultFinal)
        })
      }
      return
    }
    if (token === "APE" && (chainId || !address)) {
      {// @ts-ignore
        const { ethereum } = window
// @ts-ignore
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)
        const tokenContract = new ethers.Contract(contractAddressApeApprove, abiApeApprove, provider)
        const balanceData = await tokenContract.balanceOf(contractAddressApe)
        setTotalBalance(total + Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`apeResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`apeResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`apeResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
          .reduce((accumulator, object) => {
            return accumulator + object.depositIndices
          }, 0)
        const depositStatusOtherData = await nftContract.calculateInterest(address, indexResult)
        setInterestNotCollected(
          !!result.filter(i => i.lockupPeriods === getPlan())?.length ? Number(depositStatusOtherData) / busd : 0,
        )
      }
    }
    if (token === "BUSD") {
      {// @ts-ignore
        const { ethereum } = window
// @ts-ignore
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressBusd, abiBusd, provider)

        const tokenContract = new ethers.Contract(contractAddressBusdApprove, abiBusdApprove, provider)
        if (localStorage.getItem(`busdBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`busdBalance${plan}`))
        }
        const balanceData = await tokenContract.balanceOf(contractAddressBusd)

        setTotalBalance(total + (Number(balanceData) / busd))
        setToLocalStorage(`busdBalance${plan}`, total + (Number(balanceData) / busd))

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`busdResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`busdResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`busdResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
          setInterestNotCollected(resultFinal)
        })
      }
    }
    if (token === "CAKE") {
      {// @ts-ignore
        const { ethereum } = window
// @ts-ignore
        const provider = new ethers.providers.Web3Provider(ethereum)

        const tokenContract = new ethers.Contract(contractAddressCakeApprove, abiCakeApprove, provider)
        if (localStorage.getItem("cakeBalance") !== null) {
          setTotalBalance(getFromLocalStorage("cakeBalance"))
        }
        setTotalBalance(total )
        setToLocalStorage("cakeBalance", total)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "BABYDOGE") {
      {// @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)


        const tokenContract = new ethers.Contract(contractAddressBabyDogeApprove, abiBabyDogeApprove, provider)
        if (localStorage.getItem("babyBalance") !== null) {
          setTotalBalance(getFromLocalStorage("babyBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000000)
        setToLocalStorage("babyBalance", Number(balanceData) / 1000000000)

        setApproved(total)
      }
    }
    if (token === "TWT") {
      {
        if (localStorage.getItem("twtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("twtBalance"))
        }
        setTotalBalance(total)
        setToLocalStorage("twtBalance", total)
      }
    }
    if (token === "GMT") {
      {
        if (localStorage.getItem("gmtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("gmtBalance"))
        }

        setTotalBalance(total)
        setToLocalStorage("gmtBalance", total)
      }
    }
    if (token === "C98") {
      {
        if (localStorage.getItem("c98Balance") !== null) {
          setTotalBalance(getFromLocalStorage("c98Balance"))
        }

        // const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(total)
        setToLocalStorage("c98Balance", total)
      }
    }
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const buy = async () => {
    if (!input.length) {
      alert("Fill input value")
      return
    }
    const toWei = amount => Web3.utils.toWei(amount)
    // @ts-ignore
    const web3 = new Web3(library.provider)

    if (token === "ETH") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiEth, contractAddressEth)
      if (isM) {
        await toast.promise(
          web3Contract.methods
            .deposit(plan, search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394")
            .send({
              value: toWei(input),
              from: address,
            })
            .then(() => {


              getAllInfo()
              if (!!search.get("ref")) {

              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        apiBeaconcha.getGas().then(async (r) => {
          await toast.promise(
            web3Contract.methods
              .deposit(plan, search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394")
              .send({
                value: toWei(input),
                from: address,
                gasPrice: r.data.fast
              })
              .then(() => {

                getAllInfo()
                if (!!search.get("ref")) {

                }
                setInput("")
              }),
            {
              loading: 'Waiting for deposit transaction',
              success: <b>Deposited {Number(input)}! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        })
      }
    }
    if (token === "BNB") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBnb, contractAddressBnb)

      await toast.promise(
        web3Contract.methods
          .deposit(plan, search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394")
          .send({
            value: toWei(input),
            from: address,
          })
          .then(() => {
            getAllInfo()


            setInput("")
          }),
        {
          loading: 'Waiting for deposit transaction',
          success: <b>Deposited {Number(input)}! ✅</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "USDT") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abi, contractAddressUsdt)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiApprove, approveAddress)
      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              (Number(input) * 1000000).toString(),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              getAllInfo()

              if (!!search.get("ref")) {

              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressUsdt, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    (Number(input) * 1000000).toString(),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    getAllInfo()

                    if (!!search.get("ref")) {

                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }

    }
    if (token === "BUSD") {
      // @ts-ignore
      const web3ContractSecond = new web3.eth.Contract(abiBusd, contractAddressBusd)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiBusdApprove, contractAddressBusdApprove)
      if (approved >= Number(input)) {
        await toast.promise(
          web3ContractSecond.methods
            .deposit(
              toWei(input),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              getAllInfo()


              if (!!search.get("ref")) {

              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressBusd, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3ContractSecond.methods
                  .deposit(
                    toWei(input),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    getAllInfo()


                    if (!!search.get("ref")) {

                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "USDC") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUsdc, contractAddressUsdc)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiUsdcApprove, contractAddressUsdcApprove)

      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              toWei(input),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              getAllInfo()


              if (!!search.get("ref")) {

              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .increaseAllowance(contractAddressUsdc, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    toWei(input),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {


                    getAllInfo()
                    if (!!search.get("ref")) {

                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "UNI") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUni, contractAddressUni)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiUniApprove, contractAddressUniApprove)

      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              toWei(input),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              getAllInfo()


              if (!!search.get("ref")) {

              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressUni, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    toWei(input),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    getAllInfo()


                    if (!!search.get("ref")) {

                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }), {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )

      }
    }
    if (token === "LINK"&& chainId !== 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLink)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiLinkApprove, contractAddressLinkApprove)

      if (approved >= Number(input)) {
        web3Contract.methods
          .deposit(
            toWei(input),
            plan,
            search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
          )
          .send({
            from: address,
          })
          .then(() => {
            getAllInfo()



            if (!!search.get("ref")) {

            }
            setInput("")
          })
      } else {
        await tokenContract.methods
          .increaseApproval(contractAddressLink, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
          .send({ from: address })
          .then(res => {
            web3Contract.methods
              .deposit(
                toWei(input),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                getAllInfo()


                if (!!search.get("ref")) {

                }
                setInput("")
              })
          })
      }
    }
    if (token === "LINK"&& chainId === 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLinkArbitrum)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiStandartArbApprove, contractAddressLinkArbitrumApprove)
      const gasPrice = await getGasPrice(config, {
        chainId: 42161,
      })

      if (approved >= Number(input)) {
        web3Contract.methods
          .deposit(
            toWei(input),
            plan,
            search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
          )
          .send({
            from: address,
            gas: 0x5208,
            gasPrice,
          })
          .then(() => {
            getAllInfo()



            if (!!search.get("ref")) {

            }
            setInput("")
          })
      } else {
        await tokenContract.methods
          .increaseAllowance(contractAddressLinkArbitrum, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
          .send({ from: address })
          .then(res => {
            web3Contract.methods
              .deposit(
                toWei(input),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
                gas: 0x5208,
                gasPrice,
              })
              .then(() => {
                getAllInfo()



                if (!!search.get("ref")) {

                }
                setInput("")
              })
          })
      }
    }
    if (token === "WBTC" && chainId !== 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiWbtc, contractAddressWbtc)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiWbtcApprove, contractAddressWbtcApprove)

      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              (Number(input) * 100000000).toString(),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              getAllInfo()



              if (!!search.get("ref")) {

              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .increaseApproval(contractAddressWbtc, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    (Number(input) * 100000000).toString(),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    getAllInfo()


                    if (!!search.get("ref")) {

                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "WBTC" && chainId === 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiWbtc, contractAddressWbtcArbitrum)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiStandartArbApprove, contractAddressWbtcArbitrumApprove)

      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              (Number(input) * 100000000).toString(),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              getAllInfo()



              if (!!search.get("ref")) {

              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .increaseAllowance(contractAddressWbtcArbitrum, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    (Number(input) * 100000000).toString(),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    getAllInfo()


                    if (!!search.get("ref")) {

                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "ARB") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiArb, contractAddressArbArbitrum)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiArbApprove, contractAddressArbArbitrumApprove)

      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              (Number(input) * busd).toString(),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              getAllInfo()



              if (!!search.get("ref")) {

              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .increaseAllowance(contractAddressArbArbitrum, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    (Number(input) * busd).toString(),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    getAllInfo()


                    if (!!search.get("ref")) {

                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "APE") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiApe, contractAddressApe)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiApeApprove, contractAddressApeApprove)

      if (approved >= Number(input)) {
        web3Contract.methods
          .deposit(
            toWei(input),
            plan,
            search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
          )
          .send({
            from: address,
          })
          .then(() => {
            getAllInfo()



            if (!!search.get("ref")) {

            }
            setInput("")
          })
      } else {
        await tokenContract.methods
          .approve(contractAddressApe, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
          .send({ from: address })
          .then(res => {
            web3Contract.methods
              .deposit(
                toWei(input),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                getAllInfo()



                if (!!search.get("ref")) {

                }
                setInput("")
              })
          })
      }
    }
  }
  const claiminterest = async (amount: string) => {
    // @ts-ignore
    const web3 = new Web3(library.provider)

    if (token === "BNB") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBnb, contractAddressBnb)

      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }

    if (token === "USDT") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abi, contractAddressUsdt)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "BUSD") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBusd, contractAddressBusd)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "USDC") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUsdc, contractAddressUsdc)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "UNI") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUni, contractAddressUni)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "LINK" && chainId !== 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLink)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

          }),
        {
          loading: 'Waiting for claim interests',
          success: <b>Claimed is success!</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "LINK" && chainId === 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLinkArbitrum)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

          }),
        {
          loading: 'Waiting for claim interests',
          success: <b>Claimed is success!</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "WBTC" && chainId !== 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiWbtc, contractAddressWbtc)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "WBTC" && chainId === 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiWbtc, contractAddressWbtcArbitrum)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "ARB" && chainId === 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiArb, contractAddressArbArbitrum)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "APE") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiApe, contractAddressApe)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {

          }),
        {
          loading: 'Waiting for claim interests',
          success: <b>Claimed is success!</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
  }

  useEffect(() => {
    if (remainingTime) {
      // @ts-ignore
      let remainingTimeData = (new Date()) - (new Date(remainingTime))
      var downloadTimer = setInterval(function () {
        if (remainingTimeData<= 0) {
          clearInterval(downloadTimer)
        }
        // @ts-ignore
        const weekdays     = Math.floor(remainingTimeData/1000/60/60/24/7);
        const days         = Math.floor(remainingTimeData/1000/60/60/24 - weekdays*7);
        const hours        = Math.floor(remainingTimeData/1000/60/60    - weekdays*7*24            - days*24);
        const minutes      = Math.floor(remainingTimeData/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
        const seconds      = Math.floor(remainingTimeData/1000          - weekdays*7*24*60*60      - days*24*60*60      - hours*60*60      - minutes*60);
        var dDisplay = days > 0 ? days + (days == 1 ? " d " : " d ") : ""
        var hDisplay = hours > 0 ? hours + (hours == 1 ? " h " : " h ") : ""
        var mDisplay = minutes > 0 ? minutes + (minutes == 1 ? " m " : " m ") : ""
        var sDisplay = seconds > 0 ? seconds + (seconds == 1 ? " s" : " s") : ""
        setTimeLeftOther(dDisplay + hDisplay + mDisplay + sDisplay)
        remainingTimeData += 1000
      }, 1000)
    }
  }, [remainingTime])

  const getDisabled = () => {
    if (token === 'ETH') {
      return false
    }
    if (token === 'USDT') {
      return false
    }
    if (token === "WBTC") {
      return false
    }
    if (token === "UNI") {
      return false
    }
    if (token === "BNB") {
      return false
    }
    if (token === "ARB") {
      return false
    }
    if (token === "APE") {
      return false
    }
    if (token === "USDC") {
      return false
    }
    return token !== "LINK";

  }

  const getText = () => {
    if (token === "ETH") {
      return 'Stake'
    }
    if (token === "USDT") {
      return 'Stake'
    }
    if (token === "UNI") {
      return 'Stake'
    }
    if (token === "BNB") {
      return 'Stake'
    }
    if (token === "USDC") {
      return 'Stake'
    }
    if (token === 'LINK') {
      return 'Stake'
    }
    if (token === 'ARB') {
      return 'Stake'
    }
    if (token === 'APE') {
      return 'Stake'
    }
    if (token === 'WBTC') {
      return 'Stake'
    }
    return 'Full'
  }

  const getZerosToDivide = () => {
    switch (token) {
      case "USDT":
        return 1000000
      case 'WBTC':
        return 100000000
      default:
        return busd
    }
  }

  const getRewards = () => {
    if (address === '0x165D89125d3857ACF40C60d9cee03B8AA1c598e8' && plan === '14' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.00092869
    }

    return withdrawalTotal > 0 ? withdrawalTotal.toFixed(9) : withdrawalTotal
  }

  const getProfit = () => {
    if (address === '0xb7a6B5b903a3fc703a1228080076677f9fE46E2B'&& plan === '14' && token === 'ETH' && isNew) {
      return 0.0347167322
    }
    return interestNotCollected.toFixed(9)
  }

  return (
    <div
      id={plan === "1" ? token : undefined}
      style={{ minHeight: height }}
      className={cn("collapse-table-wrapper", {
        open: isOpen,
        hide: stakedDisplay && !resultArray?.length,
      })}
    >
      <button onClick={toggleOpen} ref={titleRef} className="collapse-table-title">
        <div className="collapse-table-title-left">
          <img src={img} alt="token" className="collapse-table-title-left-img" />
          <div className="collapse-table-wrapper-name">{token}</div>
          <div className="collapse-table-wrapper-name">{day}-Day Staking Pool</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">{token === "ETH" ? "Coin Staked" : "Token Staked"}</div>
          <div className="collapse-table-wrapper-other-value">
            {(address === '0x0Fcddc94140Eba1dd9bE3471357b5990Fd946EA4' && plan === '14') || (address === '0xf0492910AAEdeb9411c9555753D0ea70A9b92A6c' && plan === '14') ? '0' :resultArray?.reduce((accumulator, object) => {
              return accumulator + object.stakedAmounts / getZerosToDivide()
            }, 0)}{" "}
            ({token})
          </div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Stake Limit</div>
          <div className="collapse-table-wrapper-other-value">{quantity}</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Periodic % Profit</div>
          <div className="collapse-table-wrapper-other-value">{percent}%</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Liquidity</div>
          <div className="collapse-table-wrapper-other-value">
            ~{totalBalance === 0 ? 'Loading...' : moneyFormatter.format(totalBalance * stat?.find(iOther => iOther.id === id)?.current_price)}
          </div>
        </div>
        <div className="collapse-table-wrapper-btn">
          <ArrowIcon className="collapse-table-arrow" />
        </div>
      </button>
      <div className="collapse-table-content">
        <div className="collapse-table-content-left">
          <div className="collapse-table-content-left-block">
            <div className="collapse-table-content-left-block-label">Total locked:</div>
            <div className="collapse-table-content-left-block-value">
              {totalBalance === 0 ? 'Loading...' : totalBalance} {token}
            </div>
          </div>
          <div className="collapse-table-content-left-block">
            <div className="collapse-table-content-left-block-label">Last claim time:</div>
            <div className="collapse-table-content-left-block-value">{timeleftOther}</div>
          </div>
          <div className="collapse-table-content-left-block">
            <div className="collapse-table-content-left-block-label">Rewards collected</div>
            <div className="collapse-table-content-left-block-value">
              {getRewards() < 0 ? 0 : getRewards()} {token}
            </div>
          </div>
          <Link to={`${routes.swapInfo}?tab=pools&tokenSelected=${token}&${search.toString()}`} className="collapse-table-content-left-link">
            See Pool Info <Export />
          </Link>
          {token !== "ETH" && (
            <button onClick={() => connectToToken(token, chainId)} className="collapse-table-content-left-link">
              Add to MetaMask <Metamask />
            </button>
          )}
        </div>
        <div className="collapse-table-content-right">
          <div className='collapse-table-content-right-top'>
            <div className="collapse-table-content-right-block">
              <div className="collapse-table-content-right-block-left">
                <div className="collapse-table-content-right-block-left-title">Recent Coin Profit</div>
                <div className="collapse-table-content-right-block-left-value">{getProfit() < 0 ? 0 : getProfit()} <span>{token}</span></div>
              </div>
              <SimpleButton
                onClick={() => {
                  claiminterest(interestNotCollected.toString())
                }}
                text={disableClaim ? 'Claiming...' : 'Claim Rewards'}
                variant="colored"
                disabled={disableClaim || change}
              />
            </div>
            <div className="collapse-table-content-right-block">
              <div className="collapse-table-content-right-block-left">
                <div className="collapse-table-content-right-block-left-title">Start Staking</div>
                <Input onChange={v => setInput(v)} type="number" value={input} placeholder={`0 ${token}`}/>
              </div>
              <SimpleButton
                variant="colored"
                text={getText()}
                onClick={buy}
                disabled={getDisabled()}
              />
            </div>
          </div>
          {resultArray?.map((i, index) => (
            <CollapseTableWithdrawal interestNotCollected={interestNotCollected} changed={change} plan={plan} token={token} i={i} getAllInfo={getAllInfo}
                                     key={index} index={index} openCancel={openCancel} isNew={isNew}/>
          ))}
          {/*{(token === 'ETH' && chainId !== 42161 && !!resultArray?.length) &&*/}
          {/*    <SimpleButton*/}
          {/*        className='full'*/}
          {/*        text={change ? 'Transferring funds...' : 'Transfer funds'}*/}
          {/*        onClick={() => {*/}
          {/*          setChange(true)*/}
          {/*          window.scrollTo({*/}
          {/*            top: 0,*/}
          {/*          })*/}
          {/*          openTransfer(plan, resultArray.reduce((partialSum, a) => partialSum + (Number(a.stakedAmounts) / busd), 0), interestNotCollected, resultArray.length, allRecordsForModal)*/}
          {/*        }}*/}
          {/*        disabled={change}*/}
          {/*    />*/}
          {/*}*/}
        </div>
      </div>
    </div>
  )
}
