import React from "react"

import img1 from "assets/icons/investors/1.svg"
import img2 from "assets/icons/investors/2.svg"
import img3 from "assets/icons/investors/3.svg"
import img4 from "assets/icons/investors/4.svg"
import img5 from "assets/icons/investors/5.svg"
import img6 from "assets/icons/investors/6.svg"
import img7 from "assets/icons/investors/7.svg"
import img8 from "assets/icons/investors/8.svg"
import img9 from "assets/icons/investors/9.svg"
import "./Investors.scss"

export const Investors = (): JSX.Element => {
  const investors = [
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img3,
    },
    {
      img: img4,
    },
    {
      img: img5,
    },
    {
      img: img6,
    },
    {
      img: img7,
    },
    {
      img: img8,
    },
    {
      img: img9,
    },
  ]
  return (
    <div className="investors">
      <h3 className="investors-title">Our Investors</h3>
      <div className="investors-content">
        {investors.map((block, index) => (
          <img src={block.img} alt="partner" key={index} className="investors-content-item" />
        ))}
      </div>
    </div>
  )
}
