export const contractAddressUsdt = "0x3577662e826f82b7d915f7b6fd32bb3fe1be6511";
export const contractAddressBnb = "0x1bea112a4ba183fcdb3d9b8bc7a144cb8a01a532";
export const contractAddressBusd = "0xa334febed088fe12894a9c714740265373184272";
export const contractAddressUsdc = "0x9f40cf150192336023542eafb7be723b5b2a306c";
export const contractAddressUsdcApprove = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
export const contractAddressLink = "0xe80db01d89ade8d035b2ac4507e4899c31145470";
export const contractAddressLinkApprove = "0x514910771af9ca656af840dff83e8264ecf986ca";
export const contractAddressWbtc = "0xbe5a058d1db68ffba2c553082a2fd80aaaf0e5de";
export const contractAddressWbtcApprove = "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599";
export const contractAddressUni = "0xa5aa0241a4e777c2ba1d98cbd2e1aba9d5580cd3";
export const contractAddressUniApprove = "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984";
export const contractAddressApe = "0xe94b7f5b6E1deD277Dcaf745A879D2faE8a98fA9";
export const contractAddressApeApprove = "0x4d224452801aced8b2f0aebe155379bb5d594381";
export const contractAddressEth = "0xc95242cce9dedd7d03f7be80e5997d4972cd073f";
export const contractAddressArbArbitrum = "0x0bbca4d6defec65d4e42f7762329b9351bfcf0b6";
export const contractAddressArbArbitrumApprove = "0x912CE59144191C1204E64559FE8253a0e49E6548";
export const contractAddressWbtcArbitrum = "0xbf2cb2a97ff49d13ad88dd2dad7aaa39d8621dbe";
export const contractAddressWbtcArbitrumApprove = "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f";
export const contractAddressLinkArbitrum = "0xe9dd8300dbfb789052104e458ed6298ea0cd202b";
export const contractAddressLinkArbitrumApprove = "0xf97f4df75117a78c1a5a0dbb814af92458539fb4";
export const contractAddressBusdApprove = "0xe9e7cea3dedca5984780bafc599bd69add087d56";
export const contractAddressCakeApprove = "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82";
export const contractAddressBabyDogeApprove = "0xc748673057861a797275cd8a068abb95a902e8de";
export const contractAddressTwtApprove = "0x4b0f1812e5df2a09796481ff14017e6005508003";
export const contractAddressGmtApprove = "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1";
export const contractAddressC98Approve = "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6";
export const approveAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7";
