import "animate.css"
import "styles/global.scss"
import {Router} from "./service/router/Router";
import {Toaster} from "react-hot-toast";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";

function App() {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <Router />
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#dce3ed",
          },
        }}
      />
    </>
  );
}

export default App;
